import { Roboto_Condensed } from 'next/font/google';
import {
  FaWindowClose,
  FaShieldVirus,
  FaHeadSideMask,
  FaPumpMedical,
} from 'react-icons/fa';
import RequestOrderInput from '../components/RequestOrderInput';
import { hasClass } from '../helpers';
import { Flex, Box, Text, useMediaQuery, HStack } from '@chakra-ui/react';
import Image from 'next/image';
import Layout from '../components/Layout';
import Carousel from '../components/Carousel';
import Seo from '../components/Seo';
import DownloadApp from '../components/DownloadApp';
import { useRouter } from 'next/router';
import { GET_CO2_SAVINGS } from '../apollo/queries/GetCo2Savings';
import { useQuery } from '@apollo/client';
import { MdEnergySavingsLeaf } from 'react-icons/md';

const SAFETY = [
  {
    icon: <FaHeadSideMask size={'30px'} color="#11a00d" />,
    title: 'Face Coverings',
    description: `We provide complementary face masks if you require one.
    Rest-assure, our Chauffeurs will be wearing their masks if you
    require so!`,
  },
  {
    icon: <FaPumpMedical size={'30px'} color="#11a00d" />,
    title: 'Cleaning & Sanitisation',
    description: `So you can have the best experience, at Vert the cars must be
    kept in pristine conditions. Our Chauffeurs are always paying
    attention for the cars to be cleaned and sanitised after each
    ride!`,
  },
  {
    icon: <FaShieldVirus size={'30px'} color="#11a00d" />,
    title: 'Chauffeur safety training',
    description: `All our Chauffeurs are attending and successfully completing one
    of the most comprehensive training programme in the industry
    before they start driving with us. With Vert, Safety comes as a
    Standard!`,
  },
];

// Subsets are really important.
const roboto = Roboto_Condensed({
  weight: '400', // if single weight, otherwise you use array like [400, 500, 700],
  style: 'normal', // if single style, otherwise you use array like ['normal', 'italic']
  subsets: ['latin'],
});

const Home = ({ googleApiKey, jwtToken }) => {
  const router = useRouter();

  const { data, loading, error } = useQuery(GET_CO2_SAVINGS, {
    context: {
      clientName: 'vert',
    },
  });

  const onClickCloseVideo = () => {
    const videoSrc = document.querySelector('#player-1');
    const videoPopup = document.querySelector('.video-popup');

    const isOpen = hasClass(videoPopup, 'open-video');
    if (isOpen) {
      videoPopup.classList.remove('open-video');
      videoSrc.setAttribute('src', '');
    }
  };

  const [isLargerThan1070] = useMediaQuery('(min-width: 1070px)');
  const [isLargerThan728] = useMediaQuery('(min-width: 728px)');
  const [isLargerThan1350] = useMediaQuery('(min-width: 1350px)');

  let location = 'London and Essex';

  const string = router.query.location;

  if (typeof string === 'string') {
    // Check if string is a valid string
    location = string.replace(/([A-Z])/g, ' $1');
  }

  let seo: {
    metaTitle: string;
    metaDescription?: string;
  } = {
    metaTitle: `${location} All-Electric Chauffeur Service`,
  };

  if (location !== 'London and Essex') {
    seo.metaDescription = `${location} chauffeur service discover our exceptional location Chauffeur Service. Whether you need executive travel, airport transfers, or personalized city tours, our${location} services offer the epitome of luxury and convenience. Explore London in style with our all-electric chauffeur rides.`;
  }

  return (
    <Layout withAppNavBar={false} willAnimate={true}>
      <Seo seo={seo} />
      <Flex
        as="section"
        backgroundColor={'#0b0b0b'}
        direction={'column'}
        alignItems={'center'}
      >
        <Flex
          direction="row"
          justifyContent="center"
          // alignItems="center"
          position="relative"
          maxW={'600px'}
        >
          <Flex
            direction="column"
            py={'60px'}
            w="100%"
            h="100%"
            paddingTop={isLargerThan728 ? 200 : 120}
          >
            <Flex direction="column" w={isLargerThan728 ? '550px' : '350px'}>
              {!loading && data.getTotalCo2Saving && (
                <Box mb={10}>
                  <Text
                    className={roboto.className}
                    color={'white'}
                    fontWeight={'bold'}
                    fontSize={'2xl'}
                  >
                    CO2 Saved to Date
                  </Text>
                  <HStack alignItems={'center'}>
                    <MdEnergySavingsLeaf color="#11a00d" />
                    <Text color={'brand.500'} m={0}>
                      {(data.getTotalCo2Saving.total_co2_saved / 1000).toFixed(
                        2
                      )}
                    </Text>
                    <Text color={'white'} m={0}>
                      metric tonnes
                    </Text>
                  </HStack>
                </Box>
              )}
              <Text
                as="h1"
                className={roboto.className}
                color="white"
                fontWeight="bold"
                fontSize="5xl"
                py="20px"
              >
                Chauffeur Ready When You Are!
              </Text>
              <Box py={3}>
                <Text color="white" fontSize="lg" mb={0}>
                  Chauffeur-Driven Luxury Awaits with Our All-Electric Service
                </Text>
                <Text color={'white'} fontSize={'lg'}>
                  Your next executive ride is just only tap away.
                </Text>
              </Box>
              <RequestOrderInput
                jwtToken={jwtToken}
                googleApiKey={googleApiKey}
                width={isLargerThan728 ? 500 : 300}
              />
            </Flex>
          </Flex>
          {isLargerThan1070 && (
            <Flex
              paddingTop={isLargerThan728 ? 200 : 120}
              direction="column"
              h="100%"
              top={0}
            >
              <Box className="home-img" w={'550px'} top={0}>
                <Box position={'absolute'} ml={'158px'} zIndex={1}>
                  <Image
                    className="phone-hero"
                    src="/images/firstscreen.png"
                    alt="vert app"
                    width={240}
                    height={420}
                  />
                </Box>
                <Box position={'absolute'}>
                  <Image
                    src="/images/background.png"
                    alt="background"
                    width={800}
                    height={700}
                  />
                </Box>
                <Box
                  className="circle"
                  borderWidth={1}
                  borderColor={'rgba(255, 255, 255, 0.2)'}
                  rounded={'50%'}
                  w={430}
                  h={430}
                  ml={9}
                  padding={1}
                  zIndex={9}
                  position={'absolute'}
                >
                  <Image
                    src="/images/car.svg"
                    alt="car"
                    width={110}
                    height={120}
                  />
                </Box>
              </Box>
            </Flex>
          )}
        </Flex>
      </Flex>

      <Flex
        as="section"
        my={20}
        direction="column"
        justifyContent="center"
        alignItems="center"
        px="20px"
        maxW="1524px"
        alignSelf={isLargerThan728 ? 'center' : 'auto'}
      >
        <Box
          w={isLargerThan1350 ? '1460px' : isLargerThan728 ? '1024px' : '100%'}
        >
          <Text
            as="h2"
            fontSize={isLargerThan728 ? '5xl' : '4xl'}
            className={roboto.className}
            fontWeight="semibold"
            lineHeight={isLargerThan728 ? '50px' : '35px'}
            pl={isLargerThan728 ? '120px' : 0}
          >
            Safety
          </Text>
          <Carousel paginationAndArrows={false} itemsToShowInitial={4}>
            {SAFETY.map((item, index) => (
              <Flex
                as="article"
                direction="column"
                mx={5}
                my={10}
                shadow="lg"
                key={index}
                h={isLargerThan728 ? '400px' : '450px'}
                alignItems="center"
                rounded="md"
                borderWidth={0.5}
                overflow="hidden"
                w={600}
              >
                <Flex
                  w={400}
                  height={200}
                  overflow="hidden"
                  alignItems="center"
                  justify={'center'}
                >
                  {item.icon}
                </Flex>
                <Box p={5}>
                  <Text as="h3" className={roboto.className} fontSize="2xl">
                    {item.title}
                  </Text>
                  <Text fontSize={'sm'}>{item.description}</Text>
                </Box>
              </Flex>
            ))}
          </Carousel>
        </Box>
      </Flex>
      {/* Chauffuer Service */}
      <Flex
        as="section"
        direction={isLargerThan1070 ? 'row' : 'column'}
        justify="space-between"
        mb={20}
        maxW="1224px"
        alignSelf="center"
      >
        <Box as="figure" maxW="600px" mr={isLargerThan1070 ? 50 : 0}>
          <Image
            src="/images/driver_landscape.png"
            alt="Executive Chauffeur Service - Professional Driver Landscape"
            width={600}
            height={420}
          />
        </Box>
        <Box as="article" maxW="600px" mt={10} px="20px">
          <Text
            as="header"
            m={0}
            fontSize="xl"
            fontWeight="medium"
            color="gray.600"
          >
            Our Services
          </Text>
          <Text
            as="h2"
            fontSize={isLargerThan728 ? '5xl' : '4xl'}
            className={roboto.className}
            fontWeight="semibold"
            lineHeight={isLargerThan728 ? '50px' : '35px'}
          >
            Executive Chauffeur Service
          </Text>
          <Text as="p">
            Our top hand-picked chauffeurs have completed comprehensive
            training. They provide a premium professional service on every
            journey. We offer tailored transport solutions. They include airport
            transfers, business trips, corporate events, roadshows, weddings,
            and shopping trips.
          </Text>
        </Box>
      </Flex>

      {/* Payments */}
      <Flex
        as="section"
        direction={isLargerThan1070 ? 'row' : 'column'}
        justify="space-between"
        mb={20}
        maxW="1224px"
        alignSelf="center"
      >
        <Box
          as="article"
          maxW="600px"
          mr={isLargerThan1070 ? 50 : 0}
          px="20px"
          mt={10}
        >
          <Text
            as="header"
            m={0}
            fontSize="xl"
            fontWeight="medium"
            color="gray.600"
          >
            Payment Options
          </Text>
          <Text
            as="h2"
            fontSize={isLargerThan728 ? '5xl' : '4xl'}
            className={roboto.className}
            fontWeight="semibold"
            lineHeight={isLargerThan728 ? '50px' : '35px'}
          >
            Multiple Payment Methods
          </Text>
          <Text as="p">
            Your money, your choice—personal, family, or business trips can be
            paid using credit cards or eye-credits. Eye-credits offer flexible
            options: you can purchase, share, or redistribute them as needed.
          </Text>
        </Box>
        <Box
          as="figure"
          maxW="600px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Image
            src="/images/payments.png"
            alt="Multiple Payment Options - Credit Card and Eye-Credits"
            width={600}
            height={420}
          />
        </Box>
      </Flex>

      {/* Safety */}
      <Flex
        as="section"
        direction={isLargerThan1070 ? 'row' : 'column'}
        justify="space-between"
        mb={20}
        maxW="1224px"
        alignSelf="center"
      >
        <Box as="figure" maxW="600px" mr={isLargerThan1070 ? 50 : 0}>
          <Image
            src="/images/sos.png"
            alt="Safety Features - Panic Button and Emergency Services"
            width={600}
            height={420}
          />
          {/* <Box className="circle-ripple"></Box> */}
        </Box>
        <Box as="article" maxW="600px" mt={10} px="20px">
          <Text
            as="header"
            m={0}
            fontSize="xl"
            fontWeight="medium"
            color="gray.600"
          >
            Safety
          </Text>
          <Text
            as="h2"
            fontSize={isLargerThan728 ? '5xl' : '4xl'}
            className={roboto.className}
            fontWeight="semibold"
            lineHeight={isLargerThan728 ? '50px' : '35px'}
          >
            Panic Button, Safety Rating, and Emergency Training
          </Text>
          <Text as="p">
            Safety as a Standard—Our Panic Button is directly integrated with
            emergency services, ensuring immediate response when needed. We
            offer a safety rating and review process to continuously enhance our
            services. All our Chauffeurs are certified Safety as a Standard
            affiliate members as an entry level.
          </Text>
        </Box>
      </Flex>

      {/* Engagement */}
      <Flex
        as="section"
        direction={isLargerThan1070 ? 'row' : 'column'}
        justify="space-between"
        mb={20}
        maxW="1224px"
        alignSelf="center"
      >
        <Box
          as="article"
          maxW="600px"
          mr={isLargerThan1070 ? 50 : 0}
          px="20px"
          mt={10}
        >
          <Text
            as="header"
            m={0}
            fontSize="xl"
            fontWeight="medium"
            color="gray.600"
          >
            Promotions
          </Text>
          <Text
            as="h2"
            fontSize={isLargerThan728 ? '5xl' : '4xl'}
            className={roboto.className}
            fontWeight="semibold"
            lineHeight={isLargerThan728 ? '50px' : '35px'}
          >
            Coupons & Referral Programs
          </Text>
          <Text as="p">
            We love rewarding our loyal customers! Enjoy exclusive discounts on
            rides, personalized offers from prestigious brands, and many other
            surprises as part of our loyalty programs.
          </Text>
        </Box>
        <Box
          as="figure"
          maxW="600px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Image
            src="/images/engagement.png"
            alt="Customer Engagement - Coupons and Referral Program"
            width={600}
            height={420}
          />
        </Box>
      </Flex>

      {/* Profiles */}
      <Flex
        as="section"
        direction={isLargerThan1070 ? 'row' : 'column'}
        justify="space-between"
        mb={20}
        maxW="1224px"
        alignSelf="center"
      >
        <Box as="figure" maxW="600px" mr={isLargerThan1070 ? 50 : 0}>
          <Image
            src="/images/locations.png"
            alt="Travel Profiles - Personal, Family, or Business Options"
            width={600}
            height={420}
          />
        </Box>
        <Box as="article" maxW="600px" mt={10} px="20px">
          <Text
            as="header"
            m={0}
            fontSize="xl"
            fontWeight="medium"
            color="gray.600"
          >
            Profiles
          </Text>
          <Text
            as="h2"
            fontSize={isLargerThan728 ? '5xl' : '4xl'}
            className={roboto.className}
            fontWeight="semibold"
            lineHeight={isLargerThan728 ? '50px' : '35px'}
          >
            Personal, Family, or Business
          </Text>
          <Text as="p">
            Experience one of the most adaptable and versatile travel modules on
            the market. Whether it&apos;s Personal, Family, or Business, you
            have flexible options to set up your travel profiles. Invite,
            manage, or allocate travel credits, create departments, or send a
            travel gift. These are just a few ways to personalize and manage
            your daily commute to fit your specific needs.
          </Text>
        </Box>
      </Flex>

      {/* Planner assistent */}
      <Flex
        as="section"
        direction={isLargerThan1070 ? 'row' : 'column'}
        justify="space-between"
        mb={20}
        maxW="1224px"
        alignSelf="center"
      >
        <Box as="figure" maxW="600px" mr={isLargerThan1070 ? 50 : 0}>
          <Image
            src="/images/planning.png"
            alt="Ride Planning and Scheduling Assistant"
            width={600}
            height={420}
          />
        </Box>
        <Box as="article" maxW="600px" mt={10} px="20px">
          <Text
            as="header"
            m={0}
            fontSize="xl"
            fontWeight="medium"
            color="gray.600"
          >
            Planner Assistant
          </Text>
          <Text
            as="h2"
            fontSize={isLargerThan728 ? '5xl' : '4xl'}
            className={roboto.className}
            fontWeight="semibold"
            lineHeight={isLargerThan728 ? '50px' : '35px'}
          >
            Schedule Your Rides
          </Text>
          <Text as="p">
            Effortlessly manage your rides to suit your schedule. Choose
            preferred dates, times, services, and options available within the
            app. Plan your trips in advance and enjoy a worry-free experience!
          </Text>
        </Box>
      </Flex>

      {/* Options */}
      <Flex
        as="section"
        direction={isLargerThan1070 ? 'row' : 'column'}
        justify="space-between"
        mb={20}
        maxW="1224px"
        alignSelf="center"
      >
        <Box
          as="article"
          maxW="600px"
          mr={isLargerThan1070 ? 50 : 0}
          px="20px"
          mt={10}
        >
          <Text
            as="header"
            m={0}
            fontSize="xl"
            fontWeight="medium"
            color="gray.600"
          >
            Options
          </Text>
          <Text
            as="h2"
            fontSize={isLargerThan728 ? '5xl' : '4xl'}
            className={roboto.className}
            fontWeight="semibold"
            lineHeight={isLargerThan728 ? '50px' : '35px'}
          >
            Customize Your Rides
          </Text>
          <Text as="p">
            We strive to excel in personalization. Tailor your journey to suit
            your preferences—adjust the temperature, select your preferred radio
            station or music, or even enjoy a silent ride. With our options,
            you’re in control of your travel experience.
          </Text>
        </Box>
        <Box
          as="figure"
          maxW="600px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Image
            src="/images/options.png"
            alt="Ride Customization Options - Temperature, Music, Silence"
            width={600}
            height={420}
          />
        </Box>
      </Flex>

      {/* Your Order A to Z */}
      <Flex
        as="section"
        direction={isLargerThan1070 ? 'row' : 'column'}
        justify="space-between"
        mb={20}
        maxW="1224px"
        alignSelf="center"
      >
        <Box as="figure" maxW="600px" mr={isLargerThan1070 ? 50 : 0}>
          <Image
            src="/images/notifications.png"
            alt="Order Status Notifications - Confirmed, On the Way, Arrived, Finished"
            width={600}
            height={420}
          />
        </Box>
        <Box as="article" maxW="600px" mt={10} px="20px">
          <Text
            as="header"
            m={0}
            fontSize="xl"
            fontWeight="medium"
            color="gray.600"
          >
            Your Order, A to Z
          </Text>
          <Text
            as="h2"
            fontSize={isLargerThan728 ? '5xl' : '4xl'}
            className={roboto.className}
            fontWeight="semibold"
            lineHeight={isLargerThan728 ? '50px' : '35px'}
          >
            Confirmed - On the Way - Arrived - Share - Finished
          </Text>
          <Text as="p">
            Stay informed at every stage of your journey! We provide real-time
            notifications as your trip progresses through each step. From
            confirmation to arrival, we’ll keep you updated so you can enjoy a
            safe and seamless experience.
          </Text>
        </Box>
      </Flex>

      {/* Communication */}
      <Flex
        as="section"
        direction={isLargerThan1070 ? 'row' : 'column'}
        justify="space-between"
        mb={20}
        maxW="1224px"
        alignSelf="center"
      >
        <Box
          as="article"
          maxW="600px"
          mr={isLargerThan1070 ? 50 : 0}
          px="20px"
          mt={10}
        >
          <Text
            as="header"
            m={0}
            fontSize="xl"
            fontWeight="medium"
            color="gray.600"
          >
            Communication
          </Text>
          <Text
            as="h2"
            fontSize={isLargerThan728 ? '5xl' : '4xl'}
            className={roboto.className}
            fontWeight="semibold"
            lineHeight={isLargerThan728 ? '50px' : '35px'}
          >
            Stay in Touch
          </Text>
          <Text as="p">
            Always stay connected! Whether it’s additional instructions,
            last-minute updates, or personal preferences, we ensure you can
            easily stay in touch with your Chauffeur for a seamless experience.
          </Text>
        </Box>
        <Box
          as="figure"
          maxW="600px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Image
            src="/images/get_in_touch.png"
            alt="Chauffeur Communication and Updates"
            width={600}
            height={420}
          />
        </Box>
      </Flex>

      {/* Trip Issues */}
      <Flex
        as="section"
        direction={isLargerThan1070 ? 'row' : 'column'}
        justify="space-between"
        mb={20}
        maxW="1224px"
        alignSelf="center"
      >
        <Box as="figure" maxW="600px" mr={isLargerThan1070 ? 50 : 0}>
          <Image
            src="/images/issues.png"
            alt="Support for Trip Issues - Lost Property, Incidents, Fare Adjustments"
            width={600}
            height={420}
          />
        </Box>
        <Box as="article" maxW="600px" mt={10} px="20px">
          <Text
            as="header"
            m={0}
            fontSize="xl"
            fontWeight="medium"
            color="gray.600"
          >
            Trip Issues
          </Text>
          <Text
            as="h2"
            fontSize={isLargerThan728 ? '5xl' : '4xl'}
            className={roboto.className}
            fontWeight="semibold"
            lineHeight={isLargerThan728 ? '50px' : '35px'}
          >
            Lost Property, Incidents, Fare Adjustments
          </Text>
          <Text as="p">
            We are always here to help! Sometimes, issues arise, and we’re
            prepared to assist you as best as possible. Whether it’s a lost
            item, an incident, or a fare concern, you can easily report it
            through the “Trip Issue” feature in the app. Describe your
            experience, and we’ll keep you informed throughout the resolution
            process.
          </Text>
        </Box>
      </Flex>

      <Flex
        as="section"
        direction={isLargerThan1070 ? 'row' : 'column'}
        justify="space-between"
        mb={20}
        maxW="1224px"
        alignSelf="center"
      >
        <Box as="article" mt={10} px="20px">
          <Text
            as="header"
            m={0}
            fontSize="xl"
            fontWeight="medium"
            color="gray.600"
          >
            Vert and You
          </Text>
          <Text
            as="h2"
            fontSize={isLargerThan728 ? '5xl' : '4xl'}
            className={roboto.className}
            fontWeight="semibold"
            lineHeight={isLargerThan728 ? '50px' : '35px'}
          >
            Let’s Help Our Wonderful Planet
          </Text>
          <Text as="p" mt={10}>
            Embarking on the journey to become a carbon-neutral business not
            only fosters positive feelings among customers but also instills
            pride in employees who play an active role in creating a safer,
            greener environment.
            <br />
            <br />
            As more companies embrace eco-friendly practices, the goal of going
            carbon neutral becomes a powerful vision, establishing a reputation
            as a forward-thinking, environmentally responsible business.
            <br />
            <br />
            We are proud to offer you the ultimate green Chauffeur service,
            contributing to reduced carbon emissions with every journey.
            <br />
            <br />
            Every Vert mile is a breath of fresh air!
          </Text>
        </Box>
      </Flex>

      <Box className="video-popup">
        <Box className="video-popup-inner">
          <Box
            onClick={() => onClickCloseVideo()}
            position={'absolute'}
            cursor={'pointer'}
            zIndex={9}
          >
            <FaWindowClose size={35} color="white" />
          </Box>
          <Box h={'20px'}></Box>
          <Box className="iframe-box" mt={30}>
            <iframe
              id="player-1"
              src=""
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Box>
        </Box>
      </Box>
      <DownloadApp type="client" />
    </Layout>
  );
};

export async function getServerSideProps() {
  return {
    props: {
      baseUrl: process.env.NEXT_APP_BASE_URL,
      basicUser: '$2b$10$' + process.env.NEXT_VERT_BASIC_USER,
      basicPwdOne: '$2b$10$' + process.env.NEXT_VERT_BASIC_PWD_PART_ONE,
      basicPwdTwo: process.env.NEXT_VERT_BASIC_PWD_PART_TWO,
      googleApiKey: process.env.NEXT_GOOGLE_API_KEY,
      jwtToken: process.env.JWT_SECRET,
    },
  };
}

export default Home;
